import VueJwtDecode from "vue-jwt-decode";
import { useAuth0 } from "@auth0/auth0-vue"
import {reactive} from 'vue'

// const permissions = ref([])
//
// // Function to fetch permissions
async function fetchPermissions() {
    console.log("Fetch Permissions")
    try{
        const token = await useAuth0().getAccessTokenSilently()
        PermissionsModule.permissions= VueJwtDecode.decode(token).permissions

    } catch(error){
        PermissionsModule.permissions = []
    }
}

const PermissionsModule = reactive({
    permissions: [],

   hasPermission(permission){
       if (Array.isArray(permission)) {
           for (let i = 0; i < permission.length; i++) {
               const found = this.permissions.includes(permission[i]);
               if(found){
                   return true
               }
           }
       } else if (typeof permission === 'string') {
           return this.permissions.includes(permission)
       } else {
           return false
       }
    },

    async updatePermissions() {
        await fetchPermissions();
    }
});

export default PermissionsModule;




