<script setup>
import { useAuth0 } from "@auth0/auth0-vue";
import PermissionsModule from '@/Javascript/PermissionsModule'
import callApi from '@/Javascript/callAPI'
import { watchEffect } from "vue";
import VueJwtDecode from "vue-jwt-decode";

const { loginWithRedirect, logout, user, isAuthenticated, getAccessTokenSilently } = useAuth0();

function login() {
  loginWithRedirect();
}
function logoutFunction() {
  logout({ returnTo: window.location.origin });
}


watchEffect(async () => {
  if (isAuthenticated.value) {
    try {
      // Fetch the token from Auth0
      const token = await getAccessTokenSilently()
      callApi.token = token; // Update the reactive object's token
      PermissionsModule.permissions = VueJwtDecode.decode(token).permissions
    } catch (error) {
      console.error("Failed to fetch token:", error);
    }
  } else {
    callApi.token = []; // Clear the token if not authenticated
    PermissionsModule.permissions = []
  }

});





// command to run project: npm run dev
</script>

<template>
  <header>

  </header>

  <body>
    <nav id="nav" class="navbar navbar-expand-lg navbar-light bg-light sticky-top">
      <div class="container-fluid">
        <a class="navbar-brand" href="/">
          <img src="@/assets/browser.png" alt="">
          Automotive Programming Utilities
        </a>

        <div v-if="isAuthenticated" class="navbar-text mx-auto">
          <router-link to="/Dashboard" class="nav-link " v-if="user">Welcome {{ user.name }}</router-link>
        </div>

        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent" style="flex-grow: 0">
          <ul class="navbar-nav ms-auto">
            <li class="nav-item dropdown">
              <div class="nav-link dropdown-toggle" id="adminDropdown" role="button" data-bs-toggle="dropdown"
                   aria-expanded="false">
                Nissan
              </div>
              <ul class="dropdown-menu" aria-labelledby="adminDropdown">
                <li>
                  <router-link class="dropdown-item" to="/Nissan">Homepage</router-link>
                </li>
                <li>
                  <router-link class="dropdown-item" to="/Nissan/Purchase">Purchase/Download</router-link>
                </li>
                <li>
                  <router-link class="dropdown-item" to="/Nissan/Tutorials">Tutorials</router-link>
                </li>
                <li v-if="PermissionsModule.hasPermission(['create:cvt', 'create:cvt ELITEK'])">
                  <router-link class="dropdown-item" to="/Nissan/CVTCreator">Create CVT File</router-link>
                </li>
                <li v-if="isAuthenticated">
                  <router-link class="dropdown-item" to="/Nissan/CVTDownload">View CVT Files</router-link>
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown" v-if="PermissionsModule.hasPermission(['read:users', 'read:messages'])">
              <div class="nav-link dropdown-toggle" id="adminDropdown" role="button" data-bs-toggle="dropdown"
                   aria-expanded="false">
                Admin
              </div>
              <ul class="dropdown-menu" aria-labelledby="adminDropdown">
                <li v-if="PermissionsModule.hasPermission('read:users')">
                  <router-link class="dropdown-item" to="/Users">Users</router-link>
                </li>
                <li v-if="PermissionsModule.hasPermission('read:messages')">
                  <router-link class="dropdown-item" to="/Messages">Messages</router-link>
                </li>
              </ul>
            </li>
            <li>
              <router-link to="/Test" class="nav-link" v-if="PermissionsModule.hasPermission('read:test')">Test</router-link>
            </li>
          </ul>
          <div class="nav-item ms-4 d-flex justify-content-end" v-if="!isAuthenticated">
            <button class="btn btn-sm btn-secondary" @click="login">Log in</button>
          </div>
          <div class="nav-item ms-4 d-flex justify-content-end" v-if="isAuthenticated">
            <button class="btn btn-sm btn-secondary" @click="logoutFunction">Log out</button>
          </div>
        </div>
      </div>
    </nav>
    <div id="app" class="container-fluid">
      <router-view />
    </div>
  </body>
</template>

<style src="@vueform/multiselect/themes/default.css"></style>
<style>

</style>




