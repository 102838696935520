import {useAuth0} from "@auth0/auth0-vue"
import axios from "axios";
import {reactive} from 'vue';

// const { user, isAuthenticated, isLoading } = useAuth0();

async function setToken(){
    try{
        const {user} = useAuth0();
        if(user.value){
            callApi.token = await useAuth0().getAccessTokenSilently()

        }
    }catch(error){
        // console.log("There was an error setting the token:", error)
        callApi.token = ""
    }
}



const callApi = reactive({
    url: process.env.VUE_APP_API_URL,
    elitek_url: "https://elitek-apu-n-server-uf3a2.ondigitalocean.app",
    token: "",

    // const {user} = useAuth0();
    // if(user){
    //     console.log("User Logged In")
    //     useAuth0().getAccessTokenSilently().then((token)=>{
    //         state.token = token
    //     })
    //
    // }



    // Custom function to format parameters without brackets
    formatParams(params){
        const queryString = Object.entries(params)
            .map(([key, value]) => {
                if (Array.isArray(value)) {
                    return value.map(item => `${key}=${encodeURIComponent(item)}`).join('&');
                } else if (typeof value === 'object' && value !== null) {
                    return callApi.formatParams(value, key);
                } else {
                    return `${key}=${encodeURIComponent(value)}`;
                }
            })
            .join('&');

        return queryString;
    },

    async apiGet(endpoint, data=[], server=null){
        let selected_server = callApi.url
        if(server=="ELITEK"){
            selected_server = callApi.elitek_url
        }

        if(callApi.token == ""){
            callApi.setToken()
        }
        const header = {headers:{Authorization: "Bearer "+ callApi.token}}
        return axios.get(`${selected_server}${endpoint}?${callApi.formatParams(data)}`, header)
    },

    async apiPost(endpoint, data=null, json=false, server=null){
        let selected_server = callApi.url
        if(server=="ELITEK"){
            selected_server = callApi.elitek_url
        }


        if(callApi.token == ""){
            callApi.setToken()
        }
        if(!data){
            const header = {headers:{Authorization: "Bearer "+ callApi.token}}
            return axios.post(selected_server+endpoint, header)
        }
        if(!json){
            let form = new FormData();
            for(const key in data){
                if (Array.isArray(data[key])) {
                    // It's an array, so you can loop through it
                    for (let i = 0; i < data[key].length; i++) {
                        form.append(key, data[key][i]);
                    }
                } else {
                    form.append(key, data[key]);
                }
            }
            const header = {headers:{Authorization: "Bearer "+ callApi.token}}
            return axios.post(selected_server+endpoint, form, header)
        }else{
            // const jsonData = JSON.stringify(data);
            const header = {headers:{Authorization: "Bearer "+ callApi.token}}
            return axios.post(selected_server+endpoint, data, header)
        }

    },

    async apiPut(endpoint, data, server=null){
        let selected_server = callApi.url
        if(server=="ELITEK"){
            selected_server = callApi.elitek_url
        }
        if(callApi.token == ""){
            callApi.setToken()
        }
        const header = {headers:{Authorization: "Bearer "+ callApi.token}}
        return axios.put(selected_server+endpoint, data, header)
    },

    async apiDelete(endpoint, server=null){
        let selected_server = callApi.url
        if(server=="ELITEK"){
            selected_server = callApi.elitek_url
        }
        if(callApi.token == ""){
            callApi.setToken()
        }
        const header = {headers:{Authorization: "Bearer "+ callApi.token}}
        return axios.delete(selected_server+endpoint, header)
    },

    async apiDownload(endpoint, fileName, server=null){
        const response = await callApi.apiPost(endpoint, {
            file_name: fileName
        }, false, server)
        if (response.status === 200) {
            // Create a Blob from the response data
            const blob = new Blob([response.data], { type: response.headers['content-type'] });

            // Create a link element
            const link = document.createElement('a');

            // Set the download attribute with the desired file name
            link.download = fileName;

            // Create a URL for the Blob and set it as the link's href
            link.href = window.URL.createObjectURL(blob);

            // Append the link to the document
            document.body.appendChild(link);

            // Trigger a click on the link to start the download
            link.click();

            // Remove the link from the document
            document.body.removeChild(link);
        }
        return response
    },

    async setToken(){
        setToken()
    }

})

export default callApi;